import brand from '../../assets/images/brand.jpg'

const Brand = () => {
  return (
    <div className='px-6 py-3 bg-white rounded-[20px] flex items-center absolute top-12 start-32'>
      <div className='w-[74px] h-[74px] rounded-full overflow-hidden'>
        <img className='w-full h-full object-cover' src={brand} alt='Logo' />
      </div>
      <div className='ms-5'>
        <h1 className='text-2xl font-medium leading-none'>ZARA Official</h1>
        <div className='font-light leading-none'>www.zara.com</div>
      </div>
    </div>
  )
}

export default Brand

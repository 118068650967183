import React, { useContext, useEffect, useState } from 'react'

import QRCode from 'react-qr-code'

import { ScreenContext } from '../utils/ScreenContext'

function QRCodeComponent({ value, active, slideId }) {
  const { screenId, fetchTemplateIdByScreenId } = useContext(ScreenContext)
  const [localTemplateId, setLocalTemplateId] = useState(null)

  useEffect(() => {
    if (screenId) {
      fetchTemplateIdByScreenId(screenId).then(fetchedTemplateId => {
        setLocalTemplateId(fetchedTemplateId)
      })
    }
  }, [screenId, fetchTemplateIdByScreenId])

  const qrValue = `https://qr.inapp.io/${screenId}/${localTemplateId}/${slideId}`

  if (!value || !localTemplateId) {
    return <div className='absolute inset-x-0 bottom-full flex items-center pt-4 px-4 rounded-t-[30px] bg-white' />
  }

  return (
    <div className='absolute inset-x-0 bottom-full flex items-center pt-4 px-4 rounded-t-[30px] bg-white'>
      <div style={{ height: 'auto', margin: '0 auto', maxWidth: 64, width: '100%' }}>
        <QRCode
          size={256}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={qrValue}
          viewBox={'0 0 256 256'}
        />
      </div>
      <div className='ml-2'>
        <h6 className='text-xl font-medium'>Scan me!</h6>
        <p className='font-light leading-tight' style={{ textWrap: 'balance' }}>Scan QR to access shop online.</p>
      </div>
    </div>
  )
}

export default QRCodeComponent

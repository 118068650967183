import React, { useContext, useEffect } from 'react'

import { ScreenContext } from '../utils/ScreenContext'

const InitialScreen = () => {

  // const { screenId, accessCode } = useContext(ScreenContext)

  const { screenId, accessCode, content } = useContext(ScreenContext)

  // por si acaso haría falta

  useEffect(() => {
    // console.log(`Access Code in playing screen: ${accessCode}`)
    // console.log(`Slides: ${JSON.stringify(content)}`)
  }, [accessCode, content])

  return (
    <div className='p-11 relative h-screen bg-neutral-400'>
      <h1>Screen ID: {screenId}</h1>
      <h2>Access Code: {accessCode}</h2>
    </div>
  )
}

export default InitialScreen

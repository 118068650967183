/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useContext } from 'react'

import { collection, doc, getDoc, setDoc, onSnapshot } from 'firebase/firestore'
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import InitialScreen from './components/sections/InitialScreen'
import PlayingScreen from './components/sections/PlayingScreen'
import PreviewScreen from './components/sections/PreviewScreen'
import StandbyScreen from './components/sections/StandbyScreen'
import { ScreenContext } from './components/utils/ScreenContext'
import { db } from './firebase'

// Definimos los posibles estados
const ScreenStates = {
  INITIAL: 'initial',
  STANDBY: 'standby',
  PLAYING: 'playing',
  PREVIEW: 'preview',
}

function ScreenApp() {

  const [screenState, setScreenState] = useState(ScreenStates.INITIAL)
  const location = useLocation()
  const { screenId, setScreenId, setAccessCode, content, setContent, user, screensExist, dataTrue, templateId, setTemplateId, setScreensExist } = useContext(ScreenContext)
  // const { screenId, setScreenId, accessCode, setAccessCode, slides, setContent } = useContext(ScreenContext)

  // para un objeto screen en el futuro (si se crea mas de un screen)

  // const { screenId, setScreenId, accessCode, setAccessCode, slides, setContent, screens, setScreens } = useContext(ScreenContext);

  useEffect(() => {
    const existingScreenId = localStorage.getItem('screenId')

    // comprobación de que funciona useContext

    console.log('screensExist', screensExist)
    if (screensExist) {

      const urlSearchParams = new URLSearchParams(location.search)
      const templateIdUrl = urlSearchParams.get('templateId')

      setTemplateId(templateIdUrl)

      if (templateId) {
        // Aquí, necesitamos cargar el template correspondiente a este ID y mostrarlo en modo de vista previa.
        setScreenState(ScreenStates.PREVIEW)
      } else {
        if (screensExist) {

          setScreenState(user ? content ? ScreenStates.PLAYING : ScreenStates.STANDBY : ScreenStates.INITIAL)
        }
      }

    } else {
      console.log('screensExist is false', screensExist)

    }
  }, [screensExist, user, content, templateId, location.search, setTemplateId, setScreenState])

  useEffect(() => {

    // Your logic to setup Firebase or other subscription here,
    // and set 'unsubscribe' to the function returned to unsubscribe from the service.

    if (dataTrue) {
      // Actualiza el estado de la pantalla en función de los datos
      setScreenState(user ? content ? ScreenStates.PLAYING : ScreenStates.STANDBY : ScreenStates.INITIAL)
    }

  }, [dataTrue, user, content]) // Add relevant dependencies

  return (
    <div>
      {screenState === ScreenStates.PREVIEW &&
        <PreviewScreen />
      }
      {screenState === ScreenStates.INITIAL &&
        <InitialScreen />
      }
      {screenState === ScreenStates.STANDBY &&
        <StandbyScreen />
      }
      {screenState === ScreenStates.PLAYING &&
        <PlayingScreen />
      }
    </div>
  )

}

export default ScreenApp

const StandbyScreen = () => {

  return (
    <div className='p-11 relative h-screen bg-neutral-400'>
      <h1>STANDBY Screen</h1>
      <p>Pantalla asociada a un usuario pero aun no tiene asociado un tempalte, mostraremos uno nuestro</p>
      {/* Aquí mostrarías el contenido promocional */}
    </div>
  )
}

export default StandbyScreen

import React from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import { ScreenProvider } from './components/utils/ScreenContext'
import ScreenApp from './ScreenApp'
import './index.css'

function App() {
  return (
    <Router>
      <ScreenProvider>
        <ScreenApp />
      </ScreenProvider>
    </Router>

  )
}

export default App

// v9 compat packages are API compatible with v8 code
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyB1trL2b5Vnn-XhHzAfl-mloc346vWpAlY',
  authDomain: 'in-app-c1283.firebaseapp.com',
  projectId: 'in-app-c1283',
  storageBucket: 'in-app-c1283.appspot.com',
  messagingSenderId: '969680761804',
  appId: '1:969680761804:web:97721b291aad04a1a0e369',
  measurementId: 'G-WEN7DVZKL2',
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const db = getFirestore(app)

export { db, auth }

import React, { useContext, useEffect, useState } from 'react'

import ReactPlayer from 'react-player'

import { ScreenContext } from '../utils/ScreenContext'

export default function Canvas({ containerStyle }) {

  const { activeSlide } = useContext(ScreenContext)
  // eslint-disable-next-line no-unused-vars
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize)
    console.log(windowSize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const originalWidth = 1920
  const originalHeight = 1080
  const aspectRatio = originalWidth / originalHeight

  let adjustedWidth = windowSize.width
  let adjustedHeight = windowSize.width / aspectRatio

  if (adjustedHeight > windowSize.height) {
    adjustedHeight = windowSize.height
    // eslint-disable-next-line no-unused-vars
    adjustedWidth = windowSize.height * aspectRatio
  }

  return (
    <>{activeSlide && (
      <div style={containerStyle}>
        {activeSlide?.items.map((item, index) => {
          const { x, y, type, config, width, height, src } = item
          const { text, style: { fontSize, color, font } } = config

          console.log(item)

          return (
            <div
              key={index}
              style={{
                left: `${x}px`,
                top: `${y}px`,
                width: `${width}`,
                height: `${height}`,
                position: 'absolute',
                fontSize: `${fontSize}px`,
                objectFit: 'cover',
              }}
            >
              {type === 'text' &&
              <div style={{
                fontSize: `${fontSize}px`,
                color: `${color}`,
                fontFamily: `${font}`,
              }} >{text}</div>
              }
              {type === 'video' &&
              <ReactPlayer
                url={src}
                width='100%'
                controls={false}
                height='100%'
                playing
                loop
                muted />
              }
              {type === 'image' &&
              <img style={{ margin: 0, padding: 0 }} className='w-full object-cover' src={src} alt='Random from Unsplash'/>
              }
            </div>
          )
        })}
        <br></br>
      </div>
    )}</>
  )
}

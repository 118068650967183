
const PreviewScreen = () => {

  return (
    <div>
      <h1>Preview Mode</h1>
      {/* Aquí deberías renderizar el contenido del template de la previsualización */}
    </div>
  )
}

export default PreviewScreen

// import React, { useContext } from 'react'
import React, { useState, useEffect, useContext } from 'react'
import '../../App.css'

import QRCodeComponent from './QRCode'
import { ScreenContext } from '../utils/ScreenContext'

const TimelineCard = ({ active, imageSrc, onTimeActiveChange, slide, index, timeActive, qrUrl, slideId }) => {

  const {
    activeSlide,
  } = useContext(ScreenContext)
  const [percentage, setPercentage] = useState(0)
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    let intervalId

    if (active) {
      setPercentage(0)
      const intervalIncrement = 100 / (timeActive / 1000)
      const updateInterval = 100

      const slowIntervalIncrement = intervalIncrement * updateInterval / 1000

      intervalId = setInterval(() => {
        setPercentage(prevPercentage => {
          const newPercentage = prevPercentage + slowIntervalIncrement

          if (newPercentage < 100) {
            return newPercentage
          } else {
            return 0
          }
        })
      }, updateInterval)
    } else {
      setPercentage(0)
    }

    return () => clearInterval(intervalId)
  }, [active, timeActive, activeSlide])

  return (
    <>
      <div className='relative'>
        {index === 0 && <QRCodeComponent value={qrUrl} active={active} slideId={slide.slideId}/>}

        <div className={`aspect-video ${active ? 'rounded-b-[30px] bg-white p-4' : 'rounded-[20px]'}`}>
          <img className='w-full h-full object-cover rounded-[20px] shadow-default' src={imageSrc} alt='Random from Unsplash'/>
          <p className='bg-white'>{`${slide.slideId}`}</p>
        </div>
        {active ? (
          <div className='absolute inset-x-0 -bottom-5 h-3 bg-white rounded-full p-0.5'>
            <div
              className='h-full bg-black rounded-full transition-[width]'
              style={{ width: `${percentage}%` }}
            />
          </div>
        ) : null}
      </div>
    </>
  )
}

export default TimelineCard

/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react'

import Brand from '../commons/Brand'
import Canvas from '../commons/Canvas'
import Timeline from '../commons/Timeline'
import checkCardVisibility from '../utils/checkCardVisibility'
import { ScreenContext } from '../utils/ScreenContext'

const PlayingScreen = () => {

  const { screenId, accessCode, content, activeSlide } = useContext(ScreenContext)
  // const updatedSlides = checkCardVisibility(content)

  // por si acaso haría falta

  useEffect(() => {
    // console.log(`Access Code in playing screen: ${accessCode}`)
    // console.log(`Slides: ${JSON.stringify(content)}`)
    // console.log(`Slides (updated): ${JSON.stringify(updatedSlides)}`)

    content && content.forEach(slide => {
      const leadsEnabled = slide?.config?.settings?.leads?.enabled

      console.log('Leads Enabled for Slide:', leadsEnabled)
    })
  }, [accessCode, content])

  console.log(activeSlide?.items)
  const containerStyle = {
    width: '100vw',
    height: '56.25vw',
    maxHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
  }

  return (
    < >
      {!activeSlide && <>
        <h1>Screen ID: {screenId}</h1>
        <h2>Access Code: {accessCode}</h2>
      </>}
      <Canvas containerStyle={containerStyle} />
      <Brand />
      <Timeline />
    </>
  )
}

export default PlayingScreen


const checkCardVisibility = content => {
  try {
    // Ensure content is an array
    if (!Array.isArray(content)) {
      throw new Error('Content is not an array')
    }

    // Get current day in Spanish format
    const daysMap = ['D', 'L', 'M', 'X', 'J', 'V', 'S']
    const currentDay = daysMap[new Date().getDay()]

    // Get current time in HH:mm format
    const currentTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })

    const updatedSlides = content.map(slide => {
      let isVisible = false

      // Retrieve active schedule times
      const activeSchedules = slide.config.settings.schedule.filter(s => s.active)
      const startHour = activeSchedules[0]?.value || '00:00' // Default to 00:00 if not found
      const endHour = activeSchedules[1]?.value || '23:59' // Default to 23:59 if not found

      const [startH, startM] = startHour.split(':').map(Number)
      const [endH, endM] = endHour.split(':').map(Number)

      const [currentH, currentM] = currentTime.split(':').map(Number)

      // Check if slide is active today
      const isActiveToday = slide.config.settings.daysActive.some(day => day.value === currentDay && day.active)

      if (isActiveToday) {
        if (
          currentH < startH ||
          currentH === startH && currentM < startM ||
          currentH > endH ||
          currentH === endH && currentM > endM
        ) {
          isVisible = false
        } else {
          isVisible = true
        }
      }

      return {
        ...slide,
        visibleNow: isVisible,
      }
    })

    return updatedSlides
  } catch (error) {
    // Handle the error or log it
    console.error('Error in checkCardVisibility:', error)

    return [] // Return an empty array or appropriate default value
  }
}

export default checkCardVisibility

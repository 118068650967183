/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'

import '../../App.css'

import TimelineCard from './TimelineCard'
import checkCardVisibility from '../utils/checkCardVisibility'
import { ScreenContext } from '../utils/ScreenContext'

const Timeline = () => {

  const {
    content, setContent, activeSlide,
    setActiveSlide,
  } = useContext(ScreenContext)
  const [currentCardTimeActive, setCurrentCardTimeActive] = useState(10000)
  const [visibleSlides, setVisibleSlides] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [timeActive, setTimeActive] = useState(visibleSlides[0]?.config?.settings?.duration)

  const visibleCards = visibleSlides.filter(slide => slide.visibleNow).length

  const images = [
    'https://images.unsplash.com/photo-1622547748225-3fc4abd2cca0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80',
    'https://images.unsplash.com/photo-1620121478247-ec786b9be2fa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2664&q=80',
    'https://images.unsplash.com/photo-1602099952180-b61deffbea58?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2662&q=80',
    'https://images.unsplash.com/photo-1618005198919-d3d4b5a92ead?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2574&q=80',
    'https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2664&q=80',
    'https://images.unsplash.com/photo-1620121684840-edffcfc4b878?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2664&q=80',
    'https://images.unsplash.com/photo-1576502200916-3808e07386a5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2665&q=80'
  ]

  useEffect(() => {
    const filteredVisibleSlides = checkCardVisibility(content).filter(slide => slide.visibleNow)

    setVisibleSlides(filteredVisibleSlides)
    setActiveSlide(filteredVisibleSlides[0])
    setTimeActive(filteredVisibleSlides[0]?.config?.settings?.duration)
  }, [content])

  useEffect(() => {
    setActiveSlide(visibleSlides[0])
    setTimeActive(visibleSlides[0]?.config?.settings?.duration)
  }, [visibleSlides])
  useEffect(() => {

    const timer = setTimeout(() => {

      setVisibleSlides(prevContent => {
        const [firstCard, ...restCards] = prevContent

        return [...restCards, firstCard]
      })

      setActiveSlide(visibleSlides[0])
      setTimeActive(visibleSlides[0]?.config?.settings?.duration)

    }, timeActive)

    return () => {
      clearTimeout(timer)
    }
  }, [visibleSlides, timeActive, activeSlide])

  const handleTimeActiveChange = newTimeActive => {
    setCurrentCardTimeActive(newTimeActive)
  }

  const orderedVisibleSlides = [...visibleSlides.slice(activeIndex), ...visibleSlides.slice(0, activeIndex)]

  return (
    <div className='absolute inset-x-11 bottom-7 px-20 py-8'>
      <div className='py-2 px-4 rounded-[40px] bg-gray-300/25 absolute -top-16 right-20 text-sm'>Powered by <strong>inapp.io</strong></div>
      <div className='h-full rounded-[40px] bg-gray-300/25 absolute inset-x-0 bottom-0' />
      <div className='grid grid-cols-5 content-end gap-6 relative'>

        {activeSlide && <TimelineCard
          index={0}
          active={true}
          imageSrc={images[0 % images.length]}
          onTimeActiveChange={handleTimeActiveChange}
          timeActive={timeActive}
          slide={activeSlide}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          qrUrl={ activeSlide.config?.settings?.leads?.enabled ? activeSlide.config.settings.leads.settings.urlQr : null}
        />}

        {visibleSlides.map((slide, index) => {
          const imageSrc = images[index % images.length]
          const isCardActive = index === 0
          const qrUrl = slide.config?.settings?.leads?.enabled ? slide.config.settings.leads.settings.urlQr : null
          const isIndexBeingDisplayed = index !== 0 && index < 5

          const slideId = slide.slideId

          return isIndexBeingDisplayed && (
            <TimelineCard
              key={index}
              index={index}
              active={isCardActive}
              imageSrc={imageSrc}
              onTimeActiveChange={handleTimeActiveChange}
              timeActive={timeActive}
              slide={slide}
              slideId={slideId}
              slidesCount={visibleCards}
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
              qrUrl={qrUrl} />
          )
        })}
        { visibleSlides.length > 5 && <div className='py-2 px-4 rounded-[40px] bg-gray-300/25 absolute top-1/3 -right-16 text-2xl'>{`+${visibleSlides.length - 5}`}</div> }
      </div>
    </div>
  )

}

export default Timeline

import React, { createContext, useState, useEffect, useRef } from 'react'

import { collection, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import { v4 as uuidv4 } from 'uuid'

import { db } from '../../firebase'

export const ScreenContext = createContext()

export const ScreenProvider = ({ children }) => {
  const [screenId, setScreenId] = useState(null)
  const [accessCode, setAccessCode] = useState(null)
  const [content, setContent] = useState(null)
  const [screensExist, setScreensExist] = useState(false)
  const [dataTrue, setDataTrue] = useState(false)
  const [templateId, setTemplateId] = useState(null)
  const [user, setUser] = useState(null)
  const [activeSlide, setActiveSlide] = useState()

  useEffect(() => {
    const existingScreenId = localStorage.getItem('screenId')

    console.log('existingScreenId', existingScreenId)

    if (existingScreenId) {
      // Real-time listening for the screenId existence in the database
      const unsubscribe = onSnapshot(doc(collection(db, 'screens'), existingScreenId), docSnapshot => {
        if (docSnapshot.exists()) {
          // If the screenId exists, proceed with setting state
          const data = docSnapshot.data()

          setScreenId(existingScreenId)
          setAccessCode(data.accessCode)
          setContent(data.slides)
          setTemplateId(data.templateId)
          setScreensExist(true)
        } else {
          // If the screenId does not exist in the database, remove it from localStorage
          localStorage.removeItem('screenId')
          setScreensExist(false)

          // Optionally, handle the case of an invalid screenId
          createNewScreen() // or show an error message
        }
      })

      // Clean up the subscription when the component unmounts
      return () => unsubscribe()
    } else {
      // Handle the case where there is no existing screenId in localStorage
      createNewScreen()
    }
  }, [])

  useEffect(() => {
    if (screenId) {
      fetchTemplateIdByScreenId(screenId)

      const screenDoc = doc(db, 'screens', screenId)
      const unsubscribe = onSnapshot(screenDoc, docSnapshot => {
        const data = docSnapshot.data()

        if (data) {

          setAccessCode(data.accessCode)
          setContent(data.slides)
          setUser(data.user)
          setTemplateId(data.templateId)
          setDataTrue(true)
          // Comprueba si el campo checkOnlineSignal ha cambiado
          if (data.checkOnlineSignal !== lastCheckOnlineSignal.current) { // Usa .current para acceder al valor
            // Actualiza el campo lastOnline
            setDoc(doc(collection(db, 'screens'), screenId), {
              lastOnline: new Date().toISOString(),
            }, { merge: true })
            lastCheckOnlineSignal.current = data.checkOnlineSignal // Actualiza el valor de la referencia
          }
        }
      })

      return () => unsubscribe()
    }
  }, [screenId, setAccessCode, setContent, setUser, setTemplateId, setDataTrue])

  function createNewScreen() {
    const newScreenId = generateUniqueId()
    const newAccessCode = generateAccessCode()

    setScreenId(newScreenId)
    setAccessCode(newAccessCode)
    localStorage.setItem('screenId', newScreenId)

    setDoc(doc(collection(db, 'screens'), newScreenId), {
      accessCode: newAccessCode,
      user: null,
      templateId: null,
      content: null,
      workspaceId: '',
      workspaceName: '',
    })

    setScreensExist(true)
  }

  function generateUniqueId() {
    const uuid = uuidv4()

    return uuid
  }

  function generateAccessCode() {
    return Math.random().toString(36).substr(2, 6).toUpperCase()
  }

  const lastCheckOnlineSignal = useRef(null) // Define la referencia aquí

  async function fetchTemplateIdByScreenId(screenId) {
    const docSnapshot = await getDoc(doc(collection(db, 'screens'), screenId))

    if (docSnapshot.exists()) {
      const data = docSnapshot.data()

      return data.templateId
    }
  }

  return (
    <ScreenContext.Provider value={{
      screenId, setScreenId, accessCode, setAccessCode, content, setContent, screensExist, setScreensExist, user, templateId, dataTrue, setTemplateId, activeSlide,
      setActiveSlide, fetchTemplateIdByScreenId,
    }}>
      {children}
    </ScreenContext.Provider>
  )
}
